.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.analytics_card {
  width: fit-content;
}
.card_chart_body {
  padding: 15px;
  flex-grow: 1;
}
.card_chart_body p span {
  font-weight: bold;
  font-size: 18px;
}
.bar_info {
  display: flex;
  justify-content: right;
  padding-right: 8px;
  font-size: 10pt;
  height: 20px;
}
.bar_info span {
  margin: 0 5px;
}
.img_page {
  padding: 5px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0 2px 7px;
  border-radius: 10px;
}
.img_page img {
  width: 100%;
}
.img_page .img_text {
  width: 100%;
  text-align: center;
  font-size: 18px;
}
.img_page .img_text p {
  color: black;
  margin: 0;
}
