.currency_card {
  width: 100%;
  max-width: 350px;
  min-height: 200px;
}
.currency_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.currency_wrapper .currency_card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  min-height: 75px;
  min-width: 100px;
}
.change_icon {
  width: 32px;
  height: 32px;
}
.add_icon {
  width: 32px;
  height: 32px;
}
.add_icon_sm {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 5px;
  padding: 8px;
}
.modal_wrapper {
  height: 450px;
  width: 650px;
}
.card_add_account {
  border-radius: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_add_account img {
  border: none;
  padding: 20px;
  font-size: 30px;
  width: 128px;
  height: 128px;
}
.card_add_category {
  border-radius: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_add_category img {
  border: none;
  padding: 15px;
  width: 64px;
  height: 64px;
}
