.slick_wrapper {
  overflow: hidden;
}
.slick_wrapper .background_img {
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.slick_wrapper .bg1 {
  background-image: url("/src/img/index_page/bg1.webp");
}
.slick_wrapper .bg2 {
  background-image: url("/src/img/index_page/bg2.webp");
}
.slick_wrapper .bg3 {
  background-image: url("/src/img/index_page/bg3.webp");
}
.slick_wrapper img {
  width: 100%;
  position: absolute;
}
.welcome_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  text-align: center;
}
.welcome_text h1 {
  color: #5abe91;
  font-size: 3rem;
}
.welcome_text h2.h1_title {
  color: #5abe91;
  font-size: 3rem;
}
.welcome_text h2 {
  color: #000;
  font-size: 1.5rem;
}
.welcome_text h3 {
  color: #000;
  font-size: 1.5rem;
}
.welcome_text .auth_section {
  margin-top: 20px;
}
.welcome_text .auth_section a {
  margin: 0 8px;
}
section {
  padding: 30px 0 30px 0;
}
section h2.title {
  text-align: center;
  margin: 0 0 35px 0;
  font-size: 2.5em;
  font-weight: bold;
  position: relative;
}
section h2.title:after {
  display: flex;
  content: '';
  background-color: #5abe91;
  border-radius: 10px;
  width: 150px;
  height: 5px;
  margin: 5px auto;
}
.title_icon {
  display: flex;
  justify-content: center;
}
.title_icon img {
  height: 80px;
  margin-bottom: 15px;
}
.title {
  text-align: center;
}
.content {
  padding: 0 10px 0 10px;
}
#why_choose_section {
  background-color: #f3f3f9;
  min-height: 650px;
}
#solutions {
  min-height: 650px;
}
.change_language_form {
  align-self: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  right: 0;
  padding: 5px 10px;
  margin: 10px;
  font-size: 18px;
}
.change_language_form select {
  background-color: transparent;
  border: none;
  color: #fff;
  text-shadow: #1d2124;
  padding: 0 5px;
}
.change_language_form select option {
  background-color: #5abe91;
}
