.logo_footer {
  width: 200px;
  margin: auto;
}
.title {
  text-align: center;
  color: #fff;
}
footer {
  padding: 30px 0;
  background-color: #15151e;
  color: rgba(255, 255, 255, 0.5);
}
.footer_link {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.footer_link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.footer_link:focus {
  color: rgba(255, 255, 255, 0.5);
}
