.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#delete_account_section {
  display: flex;
  align-content: center;
  justify-content: center;
}
#delete_account_section .delete_account_form_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#delete_account_section .delete_account_form_container form {
  padding: 12px;
  max-width: 600px;
  width: 50%;
  border: 4px;
}
#instruction_section {
  padding: 12px;
  margin-bottom: 12px;
}
