.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message_container {
    display: flex;
    justify-content: center;
    align-items: center;
    //border: solid LightGray 2px;
    border-radius: 3px;
    padding: 8px;
    //box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    .message_container_logo {
      flex-grow: 1;
      img {
        max-width: 600px;
        width: 100%;
        padding-bottom: 20px;
      }
    }

    .message_container_text {
      flex-grow: 1;
      h1 {
        text-align: center;
        font-size: 70pt;
      }
    }
  }
}