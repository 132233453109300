.wrapper {
  height: 60px;
}

nav {
  background-color: rgba(90, 190, 145, 1);
}

nav .navbar-brand {
  margin: 0 15px 0 0;
  color: #fff !important;
  font-size: 18px;
}

nav .navbar-brand:hover {
  color: #fff !important;
  text-decoration-line: underline;
  text-decoration: solid;
}

nav a {
  color: #fff !important;
  margin-left: 5px;
  margin-right: 5px;
}

nav a:hover {
  color: #fff;
  text-decoration: underline !important;
}
.avatar_menu{
  border-color: transparent;
  &:hover{
    background-color: transparent;
    color: #ffffff;
  }
  &:not(:disabled) {
    background-color: transparent !important;
    color: #ffffff !important;
    border-style: none;
    box-shadow: none !important;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.caret {
  color: #fff;
}

.caret:after {
  color: #fff;
}

.caret:before {
  color: #fff;
}

#menu_btn {
  padding: 0 10px 0 10px;
}

.menu_panel {
  margin-top: 10px;
}

.menu_panel li {
  //padding: 5px 10px;
}

.menu_panel a {
  color: #000;
}

.menu_panel a:hover {
  color: #000;
}

.change_language_form {
  align-self: center;
  background-color: transparent;

  select{
    background-color: transparent;
    border: none;
    color: #fff;
    text-shadow: #1d2124;
    padding: 0 5px;
    option{
      background-color: rgba(90, 190, 145, 1);
    }
  }
}

