body {
  background: #efefef no-repeat center center fixed;
  background-size: cover;
}
#registration_section {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background: #fff;
  min-width: 285px;
  max-width: 450px;
  min-height: 350px;
  padding: 40px;
}
.form h2 {
  margin: 0 0 4px 10px;
  line-height: 1;
  color: #5abe91;
  font-size: 18px;
  font-weight: 400;
}
