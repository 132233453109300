.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModal__Content {
  max-width: 720px;
  max-height: 720px;
  min-width: 400px;
  position: relative !important;
}
.ReactModal__Content .btn {
  font-size: 25px;
  height: fit-content !important;
  padding-top: 0;
  padding-bottom: 0;
}
.ReactModal__Content.lg {
  min-width: 720px;
}
