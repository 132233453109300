.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper .message_container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 8px;
}
.wrapper .message_container .message_container_logo {
  flex-grow: 1;
}
.wrapper .message_container .message_container_logo img {
  max-width: 600px;
  width: 100%;
  padding-bottom: 20px;
}
.wrapper .message_container .message_container_text {
  flex-grow: 1;
}
.wrapper .message_container .message_container_text h1 {
  text-align: center;
  font-size: 70pt;
}
