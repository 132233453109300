.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background: #fff;
  min-width: 285px;
  max-width: 450px;
  min-height: 300px;
  padding: 40px;
}
.form .title {
  margin: 0 0 4px 10px;
  line-height: 1;
  color: #5abe91;
  font-size: 18px;
  font-weight: 400;
}
.form button {
  height: 2.5em;
}
.error,
.valid {
  display: none;
}
