.btn-primary {
    background-color: #5abe91;
    color: #fff;
    border-color: #5abe91;
    border-width: 2px;
}

.btn-primary:hover,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #fff;
    color: #5abe91;
    border-color: #5abe91;
    border-width: 2px;
}


.btn-primary-reverse {
    background-color: #fff;
    color: #5abe91;
    border-color: #5abe91;
    border-width: 2px;
}

.btn-primary-reverse:hover {
    background-color: #5abe91;
    color: #fff;
    border-color: #5abe91;
    border-width: 2px;
}

.h-fit {
    height: fit-content !important;
}

.wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper .content {
    flex-grow: 1;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    background-color: #5abe91;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.5) 0 7px 29px 0;
    z-index: 1;
}

input {
    outline: none;
    display: block;
    width: 100%;
    margin: 0 0 10px;
    border: 1px solid #ccc;
    color: #000000;
    box-sizing: border-box;
    font-size: 14px;
    font-wieght: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.2s linear;

    letter-spacing: inherit;
    background: none;
    -webkit-tap-highlight-color: transparent;
    animation-duration: 10ms;
    padding: 16px 14px;
    border-radius: 4px;
}

input:focus {
    color: #333;
    border: 1px solid #5abe91;
}